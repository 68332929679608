import axios from 'axios'

const language = document.documentElement.lang

export default {
  farms: {
    baseUrl: `/cvi/farmbooking/api/${language}`,
    get({
      districtId, cancelToken
    }) {
      return axios.get(`${this.baseUrl}/farms`, {
        cancelToken: cancelToken?.token,
        params: {
          districtId
        }
      })
    },
    getTimeslots({
      farmId, fromDate, toDate, cancelToken
    }) {
      return axios.get(`${this.baseUrl}/farms/${farmId}/timeslots`, {
        cancelToken: cancelToken?.token,
        params: {
          fromDate,
          toDate
        }
      })
    },
    bookings(params) {
      return axios.post(`${this.baseUrl}/bookings/`, params)
    },
    cancelBooking(farmId) {
      return axios.put(`${this.baseUrl}/bookings/${farmId}/cancel`)
    }
  }
}
