<template>
  <div class="u-width-max u-ml--a u-mr--a">
    <div class="o-content-box">
      <template v-if="model.isAuthenticated">
        <h2 class="u-font-alternative u-mb--m">
          {{ model.dashboard.heading }}
        </h2>
        <div
          v-if="bookings && bookings.length"
          class="c-farm-bookings u-mb--m"
        >
          <div class="c-farm-bookings__headline c-farm-bookings__grid u-flex u-font-size-large">
            <strong>{{ model.dashboard.dateTimeLabel }}</strong>
            <strong>{{ model.dashboard.farmNameLabel }}</strong>
            <strong class="u-hidden@mobile">{{ model.dashboard.contactNumberLabel }}</strong>
            <strong class="u-hidden@mobile">{{ model.dashboard.pickupInfoLabel }}</strong>
          </div>
          <transition-group name="fade">
            <div
              v-for="(farm) in bookings"
              :key="farm.id"
              class="c-farm-bookings__row"
            >
              <div
                class="c-farm-bookings__row--info c-farm-bookings__grid u-cursor-pointer"
                @click="showDetails($event)"
              >
                <span>{{ farm.dateTime }}</span>
                <span>{{ farm.farmName }}</span>
                <span class="u-hidden@mobile">{{ farm.contactPhoneNumber }}</span>
                <span class="u-hidden@mobile">{{ farm.pickupInfo }}</span>
                <span class="c-farm-bookings__arrow u-ml--a">
                  <Icon
                    :icon="iconArrowDown"
                    :size="16"
                    stroke="black"
                    stroke-width="3px"
                  />
                </span>
              </div>
              <div class="c-farm-bookings__row--details c-farm-bookings__grid">
                <dl class="u-hidden@desktop">
                  <dt>{{ model.dashboard.contactNumberLabel }}</dt>
                  <dd>{{ farm.contactPhoneNumber }}</dd>
                  <dt>{{ model.dashboard.pickupInfoLabel }}</dt>
                  <dd>{{ farm.pickupInfo }}</dd>
                </dl>
                <dl>
                  <dt>{{ farm.additionalInformation[0].name }}</dt>
                  <dd>{{ farm.additionalInformation[0].value }}</dd>
                  <dt>{{ farm.additionalInformation[1].name }}</dt>
                  <dd>{{ farm.additionalInformation[1].value }}</dd>
                </dl>
                <dl
                  v-for="(item, index) in farm.additionalInformation.slice(2)"
                  :key="index"
                >
                  <dt>{{ item.name }}</dt>
                  <dd>{{ item.value }}</dd>
                </dl>

                <div
                  v-if="farm.cancellable"
                  class="u-flex u-flex-align-center u-flex-justify-end"
                >
                  <span
                    @click="showCancelBookingModal(farm)"
                  >
                    <Icon
                      class="u-cursor-pointer"
                      :icon="iconTrash"
                      :size="24"
                      fill="black"
                    />
                  </span>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
        <a
          class="c-button c-button--dark u-mb--m"
          :href="model.dashboard.bookFarm.url"
          :target="model.dashboard.bookFarm.openInNewWindow ? '_blank' : null"
          :rel="model.dashboard.bookFarm.openInNewWindow
            ? 'noreferrer noopener' : null"
          @click="goToBookingPage"
        >
          {{ model.dashboard.bookFarm.text }}
        </a>
        <div v-html="model.dashboard.bookingCancellationInformation"></div>
        <Modal
          v-model="showModal"
          :is-title-centered="false"
          :width="482"
          :title="model.dashboard.cancelBooking.title"
        >
          <DeleteBooking
            :model="model.dashboard.cancelBooking"
            :farm="farmToCancel"
            @booking-delete="deleteBooking"
            @booking-delete-cancel="closeModal"
          />
        </Modal>
      </template>
      <template v-else>
        <div class="u-width-720 u-ml--a u-mr--a">
          <h2 class="u-font-alternative">
            {{ model.info.header }}
          </h2>
          <p class="u-m--0 u-mb--m">
            {{ model.info.description }}
          </p>
          <a
            class="c-button c-button--dark u-mb--m"
            href="#login"
          >
            {{ model.info.loginButtonText }}
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import iconArrowDown from '@ds/svg/icons/stroke/arrow-down-1.svg'
import iconTrash from '@ds/svg/icons/bold/bin-1-bold.svg'
import iconAdd from '@ds/svg/icons/bold/add-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import DeleteBooking from '@/CVI/FarmBooking/components/DeleteBookingPopup.vue'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import tracking from '@/CVI/FarmBooking/lib/tracking'

export default {
  components: {
    Icon,
    Modal,
    DeleteBooking
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconArrowDown,
      iconTrash,
      iconAdd,
      bookings: this.model.dashboard.bookedFarms,
      detailsIsVisible: 'details-is-visible',
      showModal: false,
      farmToCancel: null
    }
  },
  methods: {
    showDetails(e) {
      const details = e.currentTarget.nextSibling
      if (e.currentTarget.classList.contains(this.detailsIsVisible)) {
        details.classList.remove(this.detailsIsVisible)
        e.currentTarget.classList.remove(this.detailsIsVisible)
      } else {
        details.classList.add(this.detailsIsVisible)
        e.currentTarget.classList.add(this.detailsIsVisible)
      }
    },
    closeModal() {
      this.showModal = false
    },
    deleteBooking(id) {
      this.closeModal()
      this.farmToCancel = null
      this.$nextTick(() => {
        this.bookings = this.bookings.filter(farm => farm.id != id)
      })
    },
    showCancelBookingModal(farm) {
      this.showModal = true
      this.farmToCancel = farm
    },
    goToBookingPage() {
      tracking.goToFarmBookingPage()
    }
  }
}
</script>
