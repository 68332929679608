import tracker from '@/CVI/WebCore/core-tracker'

const selectDistrict = (name) => {
  tracker.track({
    event: 'selectDistrict',
    name
  })
}

const selectFarm = (name) => {
  tracker.track({
    event: 'selectFarm',
    name
  })
}

const openBookingForm = (farmName) => {
  tracker.track({
    event: 'openBookingForm',
    farmName
  })
}

const submitBookingForm = () => {
  tracker.track({
    event: 'submitBookingForm'
  })
}

const submitBookingFormSuccess = () => {
  tracker.track({
    event: 'submitBookingFormSuccess'
  })
}

const submitBookingFormFailure = () => {
  tracker.track({
    event: 'submitBookingFormFailure'
  })
}

const createNewBooking = () => {
  tracker.track({
    event: 'createNewBooking'
  })
}

const cancelBookingForm = () => {
  tracker.track({
    event: 'cancelSubmitBookingForm'
  })
}

const goToFarmBookingPage = () => {
  tracker.track({
    event: 'goToFarmBookingPage'
  })
}


export default {
  selectDistrict,
  selectFarm,
  openBookingForm,
  submitBookingForm,
  submitBookingFormSuccess,
  submitBookingFormFailure,
  createNewBooking,
  cancelBookingForm,
  goToFarmBookingPage
}
