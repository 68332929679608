<template>
  <div>
    <h5 class="u-mb--s">
      {{ farm.dateTime }}
    </h5>
    <div class="u-font-size-large u-mb--m">
      <p class="u-m--0">
        {{ farm.farmName }}
      </p>
      <p class="u-m--0">
        {{ farm.district }}
      </p>
      <p class="u-m--0 u-mb--m">
        {{ farm.address }}
      </p>
    </div>
    <button
      class="c-button c-button--dark"
      :class="{
        'is-loading': isLoading
      }"
      @click="deleteBooking"
    >
      <span class="c-button__text">
        {{ model.confirmButtonText }}
      </span>
      <Loader
        v-if="isLoading"
        size="small"
        class="c-button__loader"
        color="medium-gray"
      />
    </button>
    <button
      class="c-button c-button--light u-ml--s"
      @click="closeModal"
    >
      {{ model.cancelButtonText }}
    </button>
  </div>
</template>

<script>
import api from '@/CVI/FarmBooking/lib/api'
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    Loader
  },
  props: {
    farm: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    async deleteBooking(e) {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      e.preventDefault()
      try {
        const { id } = this.farm
        await api.farms.cancelBooking(id)
        // delete logic will be here
        this.$emit('booking-delete', id)
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
      }
    },
    closeModal() {
      this.$emit('booking-delete-cancel')
    }
  }
}
</script>
